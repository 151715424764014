import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { Table, Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import ReactPaginate from "react-paginate";
import 'react-toastify/dist/ReactToastify.css';

export default function IncubatorsPage() {
    const [deleteId, setDeleteId] = useState("");
    const [incubators, setIncubators] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const [metadata, setMetadata] = useState({});
    const [totalPages, setTotalPages] = useState(0);

    const handleCloseDeleteModal = () => setShowDelete(false);
    const handleShowDeleteModal = () => setShowDelete(true);

    const fetchIncubators = async (selected) => {
        let page = currentPage;
        if (selected) {
            setCurrentPage(selected);
            page = selected;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data, headers } = await Axios.get(
            `${apiUrl}/api/incubators?page=${page}&size=${postsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const pagination = JSON.parse(headers.get('x-pagination'));
        setMetadata(pagination);
        setTotalPages(pagination.TotalPages);

        const incubators = data;
        setIncubators(incubators);
    };

    const handleClickDeleteModal = (id) => {
        setDeleteId(id);
        handleShowDeleteModal();
    }

    const handleDeleteClick = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.delete(`${apiUrl}/api/incubators/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Incubator successfully deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchIncubators();
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setDeleteId("");
            handleCloseDeleteModal();
        });
    }

    const fetchFile = () => {
        document.getElementById("splash").style.display = "block";
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios({
            url: `${apiUrl}/api/incubators/download`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `incubators.zip`
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            document.getElementById("splash").style.display = "none";
        }).catch(function (error) {
            console.log(error);
            document.getElementById("splash").style.display = "none";
        });
    }

    useEffect(() => {
        fetchIncubators();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Modal show={showDelete} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Rri tool</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this booster?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">
                                            Incubators
                                        </h1>
                                    </div>
                                    <div className="d-flex flex-row align-items-center w-50 float-end">
                                        <div className="input-group me-5 float-end align-right justify-content-end">
                                            <Button variant="secondary" className="btn-icon btn-md w-35px h-35px" onClick={fetchFile}>
                                                <FaIcons.FaFileCsv />
                                            </Button>
                                        </div>
                                        <div className="input-group flex-grow-1">
                                            <input placeholder="Search" className="form-control form-control-sm"></input>
                                            <Button variant="primary" className="btn btn-secondary btn-sm" onClick={() => window.location.href = '/rri-tools/create'}>Search</Button>
                                        </div>
                                        <div className="input-group ms-5">
                                            <label htmlFor="perpageList" className="col-form-label col-form-label-sm">Show per page: </label>
                                            <select name="perpageList" className="form-select form-select-sm ms-2" onChange={(e) => setPostsPerPage(e.target.value)} defaultValue={postsPerPage}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="table-responsive">
                                                    <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                        <thead className="fw-bold text-muted">
                                                            <tr>
                                                                <th className="sorting">Full title</th>
                                                                <th className="sorting">Acronym</th>
                                                                <th className="sorting">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                incubators.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                            <td className="min-w-150px">
                                                                                <div className="mb-2">{item.fullTitle}</div>
                                                                            </td>
                                                                            <td className="min-w-150px">
                                                                                <div className="mb-2">{item.acronym}</div>
                                                                            </td>
                                                                            <td className="align-items-center">
                                                                                <Button variant="danger" className="btn-icon btn-sm w-25px h-25px" onClick={() => handleClickDeleteModal(item.id)}>
                                                                                    <MdIcons.MdDeleteForever style={{ color: '#fff' }} />
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dataTables_info" id="kt_table_1_info" role="status" aria-live="polite">Showing {incubators.length} of {metadata.TotalCount} entries</div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                pageCount={totalPages}
                                                onPageChange={({ selected }) => { fetchIncubators(selected + 1) }}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"active"}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                currentPage={currentPage - 1}>
                                            </ReactPaginate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}